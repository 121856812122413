.object {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.loading {
    position: absolute;
    top: 45%;
    left: 50%;
    background: url(./gif/musicmode1.gif);
    background-size: cover;
    height: 10vh;
    width: 10vh;
}