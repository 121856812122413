html,body {
    cursor: none
}

html *,body * {
    cursor: none;
}

.c--hidden {
     opacity: 0;
}

.c--clicked {
    transform: translate(-50%, -50%) scale(0.5);
    background-color: #121212;
 }

 .cursor--link-hovered {
    transform: translate(-50%, -50%) scale(1.25);
 }

.cursor {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 4px solid #fff;
    border-radius: 100%;
    position: fixed;
    transform: translate(-50%, -50%);
    transition: all 100ms ease;
    transition-property: opacity, background-color, transform;
  }