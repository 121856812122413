body, html { 
    background-color: black;
  }

.map-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}